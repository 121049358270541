import axios from "axios";


function axiosCreate(auth, BaseUrl) {
    let headerAuth = {
        'content-type': 'application/x-www-form-urlencoded'
    };
    let baseUrl = 'https://manager.intexnet.ir/';
    if (auth) {
        headerAuth['token'] = auth
    }
    if (BaseUrl) {
        baseUrl = BaseUrl
    }

    return axios.create({
        baseURL: baseUrl,
        timeout: 30000,
        headers: headerAuth
    })
}


export default axiosCreate