let alert= {
    openNotification(root, title, text, color) {
        root.notification({
            duration: '6000',
            progress: 'auto',
            color: color,
            position: 'top-center',
            title: title,
            text: text
        })
    }
}

export default alert